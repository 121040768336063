import React from "react";
import firewall from "../assets/images/banner/networking.jpg";
import logo1 from "../assets/brand/D-Link.png";
import logo2 from "../assets/brand/Netgear.png";
import logo3 from "../assets/brand/Brocade.png";
import logo4 from "../assets/brand/Juniper.png";
import logo5 from "../assets/brand/Cisco.png";
import logo6 from "../assets/brand/hp-procurve.png";
import PageTitleArea from "../components/PageTitleArea";
import ProductSection from "./ProductSection";

const Networking = () => {
  const images = [
    { src: logo1, alt: "TechCrunch Logo 1" },
    { src: logo2, alt: "TechCrunch Logo 2" },
    { src: logo3, alt: "TechCrunch Logo 3" },
    { src: logo4, alt: "TechCrunch Logo 4" },
    { src: logo5, alt: "TechCrunch Logo 4" },
    { src: logo6, alt: "TechCrunch Logo 4" },
  ];

  return (
    <>
      <PageTitleArea
        title="Networking"
        breadcrumb="Networking"
        products="Our Products"
        condition={false}
      />
      <ProductSection
        subtitle="Networking"
        title="Involves the interconnection of computers and other devices to facilitate communication, resource sharing, and data exchange, enabling individuals and organizations to access information and services across local and global networks efficiently."
        images={images}
        backgroundImage={firewall}
      />
    </>
  );
};

export default Networking;
