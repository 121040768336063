import React from "react";
import PageTitleArea from "../components/PageTitleArea";
import ProtectSection from "../components/ProtectSection";
import Carousel from "../components/Carousel";
import ServicesSection from "../components/ServicesSection";

const Services = () => {
  return (
    <>
      <PageTitleArea
        title="Our Products "
        breadcrumb="Our Products"
        condition={true}
      />
      {/* <ProtectSection /> */}
      <div style={{ paddingBottom: "40px" }}>
        {/* <Carousel /> */}
        <ServicesSection />
      </div>
    </>
  );
};

export default Services;
