import React, { useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import firewall from "../assets/images/firewall.jpg";
import storage from "../assets/images/storage.jpg";
import endpoint from "../assets/images/banner/endpoint.png";
import wireless from "../assets/images/banner/wireless.jpg";
import ibmairwatch from "../assets/images/vmware.png";
import networking from "../assets/images/banner/networking.jpg";
import networkcable from "../assets/images/banner/networkcable.jpg";
import software from "../assets/images/banner/software.png";
import server from "../assets/images/banner/server.jpg";
import VideoAnalyticsIcon from "../assets/images/banner/service-icn.png";
import FingerPrintIcon from "../assets/images/banner/services-icn.png";
import videoConferenece from "../assets/images/video-con.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

import { GiFirewall } from "react-icons/gi";
import { FaDatabase, FaServer, FaWifi, FaVideo } from "react-icons/fa";
import {
  MdStorage,
  MdSecurity,
  MdComputer,
  MdCable,
  MdNetworkPing,
} from "react-icons/md";

const CarouselWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  .slick-dots li button:before {
    font-size: 10px;
    color: transparent;
    background-image: linear-gradient(
      32deg,
      var(--color-start, #4b2999) 0%,
      var(--color-end, #57eef1) 100%
    );
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: block;
    content: "";
  }

  .slick-dots li.slick-active button:before {
    background-image: linear-gradient(
      32deg,
      var(--color-start, #4b2999) 0%,
      var(--color-end, #57eef1) 100%
    );
    opacity: 1;
    transform: scale(1.2);
  }
  @media only screen and (max-width: 768px) {
    .slick-dots {
      display: none;
    }
  }
`;

// const Card = styled.div`
//   border-radius: 10px;
//   padding: 20px;
//   text-align: center;
//   position: relative;
// `;

// const ImageWrapper = styled.div`
//   width: 100%;
//   overflow: hidden;
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const Image = styled.img`
//   width: 400px;
//   height: 200px;
//   object-fit: scale-down;

// `;

// const IconWrapper = styled.div`
//   position: absolute;
//   top: 160px;
//   left: 50%;
//   transform: translateX(-50%);
//   background-image: linear-gradient(
//     32deg,
//     var(--color-start, #4b2999) 0%,
//     var(--color-end, #57eef1) 100%
//   );
//   padding: 15px;
//   border-radius: 50%;
//   box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
//   z-index: 2;
// `;

const Icon = styled.img`
  width: 60px;
  height: 60px;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin: 60px 0 10px 0;
`;

const Description = styled.a`
  font-size: 14px;
  font-weight: 400;
  color: #4b2999;
  &:hover {
    color: #57eef1;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 170px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
  border-radius: 50%;
  background-color: aliceblue;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;

  svg {
    width: 50px;
    height: 50px;
    color: white;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  position: relative;
  z-index: 0;
`;

const Card = styled.div`
  position: relative;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
`;

const ServiceCarousel = () => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    cssEase: "ease-in-out",
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          autoplaySpeed: 2000,
          dots: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplaySpeed: 2000,
          dots: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const services = [
    {
      title: "Firewall",
      image: firewall,
      icon: <GiFirewall />,
      description: "Read More",
      link: "/products/firewall",
    },
    {
      title: "Storage",
      image: storage,
      icon: <MdStorage />,
      description: "Read More",
      link: "/products/storage",
    },
    {
      title: "End Point Security",
      image: endpoint,
      icon: <MdSecurity />,
      description: "Read More",
      link: "/products/end-point-security",
    },
    {
      title: "Wireless",
      image: wireless,
      icon: <FaWifi />,
      description: "Read More",
      link: "/products/wireless",
    },
    {
      title: "IBM Airwatch",
      image: ibmairwatch,
      icon: <MdStorage />,
      description: "Read More",
      link: "/products/ibm-airwatch",
    },
    {
      title: "Video Conferencing",
      image: videoConferenece,
      icon: <FaVideo />,
      description: "Read More",
      link: "/products/video-conference",
    },
    {
      title: "Networking",
      image: networking,
      icon: <MdNetworkPing />,
      description: "Read More",
      link: "/products/networking",
    },
    {
      title: "Software",
      image: software,
      icon: <MdComputer />,
      description: "Read More",
      link: "/products/software",
    },
    {
      title: "Server",
      image: server,
      icon: <FaServer />,
      description: "Read More",
      link: "/products/server",
    },
    {
      title: "Network Cabling",
      image: networkcable,
      icon: <MdCable />,
      description: "Read More",
      link: "/products/network-cabling",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    });
  }, []);

  return (
    <CarouselWrapper data-aos="fade-up">
      <Slider {...settings}>
        {services.map((service, index) => (
          <Card key={index}>
            <ImageWrapper>
              <Image src={service.image} alt={service.title} />
            </ImageWrapper>
            {/* <IconWrapper> */}
            <IconWrapper>{service.icon}</IconWrapper>
            {/* </IconWrapper> */}
            <Title>{service.title}</Title>
            <Description as={Link} to={service.link}>
              {service.description} &nbsp; &gt;
            </Description>
          </Card>
        ))}
      </Slider>
    </CarouselWrapper>
  );
};

export default ServiceCarousel;
