import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const SectionContainer = styled.section`
  padding: 3rem 2rem 6rem 2rem;
  text-align: center;
  background-color: #f8f9fa;
`;

const Title = styled.h3`
  color: #4b2999;
  font-size: 14px;
  margin-bottom: 1rem;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 3rem;
`;

const FaqGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-items: start;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FaqItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: ${(props) =>
    props.isActive
      ? "0 4px 16px rgba(0, 0, 0, 0.1)"
      : "0 2px 8px rgba(0, 0, 0, 0.05)"};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
`;

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionText = styled.div`
  font-weight: 600;
  text-align: left;
`;

const Answer = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-top: 1rem;
  text-align: left;
`;

const Icon = styled.span`
  background: linear-gradient(120deg, #4b2999, #57eef1);
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: transform 0.3s ease;
`;

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <SectionContainer>
      <Title>FAQ's</Title>
      <Heading>Frequently Asked Questions</Heading>
      <FaqGrid>
        <FaqItem isActive={activeIndex === 0} onClick={() => handleToggle(0)}>
          <Question>
            <QuestionText>Lorem ipsum dolor sit amet error?</QuestionText>
            <Icon>
              {activeIndex === 0 ? <FaChevronDown /> : <FaChevronRight />}
            </Icon>
          </Question>
          {activeIndex === 0 && (
            <Answer>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </Answer>
          )}
        </FaqItem>

        <FaqItem isActive={activeIndex === 1} onClick={() => handleToggle(1)}>
          <Question>
            <QuestionText>Lorem ipsum dolor sit amet?</QuestionText>
            <Icon>
              {activeIndex === 1 ? <FaChevronDown /> : <FaChevronRight />}
            </Icon>
          </Question>
          {activeIndex === 1 && (
            <Answer>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </Answer>
          )}
        </FaqItem>

        <FaqItem isActive={activeIndex === 2} onClick={() => handleToggle(2)}>
          <Question>
            <QuestionText>Lorem ipsum dolor sit amet?</QuestionText>
            <Icon>
              {activeIndex === 2 ? <FaChevronDown /> : <FaChevronRight />}
            </Icon>
          </Question>
          {activeIndex === 2 && (
            <Answer>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </Answer>
          )}
        </FaqItem>

        <FaqItem isActive={activeIndex === 3} onClick={() => handleToggle(3)}>
          <Question>
            <QuestionText>Lorem ipsum dolor sit amet?</QuestionText>
            <Icon>
              {activeIndex === 3 ? <FaChevronDown /> : <FaChevronRight />}
            </Icon>
          </Question>
          {activeIndex === 3 && (
            <Answer>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </Answer>
          )}
        </FaqItem>
      </FaqGrid>
    </SectionContainer>
  );
};

export default FaqSection;
