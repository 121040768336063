import React from "react";
import firewall from "../assets/images/banner/networkcable.jpg";
import logo1 from "../assets/images/techcrunch.png";
import logo2 from "../assets/images/techcrunch.png";
import logo3 from "../assets/images/techcrunch.png";
import logo4 from "../assets/images/techcrunch.png";
import PageTitleArea from "../components/PageTitleArea";
import ProductSection from "./ProductSection";

const NetworkingCablingProduct = () => {
  //   const images = [
  //     { src: logo1, alt: "TechCrunch Logo 1" },
  //     { src: logo2, alt: "TechCrunch Logo 2" },
  //     { src: logo3, alt: "TechCrunch Logo 3" },
  //     { src: logo4, alt: "TechCrunch Logo 4" },
  //   ];

  return (
    <>
      <PageTitleArea
        title="Network Cabling"
        breadcrumb="Network Cabling"
        products="Our Products"
        condition={false}
      />
      <ProductSection
        subtitle="Network Cabling"
        title="Network cabling involves the installation of physical cables to connect and transmit data between computers, devices, and network infrastructure components, ensuring reliable and efficient communication within organizations."
        // images={images}
        backgroundImage={firewall}
        features={[
          "Reliable and high-speed connectivity",
          "Efficient data transmission",
          "Enhanced network performance",
          "Minimized downtime",
          "Reduced signal interference",
        ]}
      />
    </>
  );
};

export default NetworkingCablingProduct;
