import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import cyboidlogo from "../assets/images/Cyboid logo trs.png";
import { FaPhoneVolume, FaBars, FaTimes } from "react-icons/fa";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  background-color: #000219;
  color: white;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, padding 0.3s ease;

  &.sticky-active {
    background-color: #000219;
    box-shadow: 0px 0px 76px 0px rgba(194, 244, 255, 0.45);
  }

  @media (max-width: 1024px) {
    padding: 0.8rem 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.6rem 1rem;
  }
`;

const Logo = styled.div`
  img {
    width: 150px;
    height: auto;
    max-width: 100%;
    transition: transform 0.3s ease;

    ${StyledHeader}.sticky-active & {
      width: 120px;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    img {
      width: 130px;
    }
  }

  @media (max-width: 480px) {
    img {
      width: 100px;
    }
  }
`;

const Nav = styled.nav`
  flex: 1;
  display: flex;
  justify-content: center;

  ul {
    list-style: none;
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
    ul {
      flex-direction: column;
      background-color: #000219;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 1rem 0;
    }
  }

  @media (max-width: 480px) {
    ul {
      gap: 1.5rem;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #57eef1;
  }

  &.active {
    color: #57eef1;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.85rem;
    padding: 0.4rem;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 1.2rem;

  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 480px) {
    font-size: 1.3rem;
    right: 1rem;
    top: 1rem;
  }
`;

const QuoteButton = styled.p`
  background-image: linear-gradient(120deg, #4b2999 0%, #57eef1 100%);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;

  &:hover {
    background-image: linear-gradient(120deg, #57eef1 0%, #4b2999 100%);
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 1024px) {
    padding: 0.6rem 1.2rem;
  }
`;

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const closeNav = () => {
    setIsNavOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StyledHeader className={isSticky ? "sticky-active" : ""}>
      <Logo as={Link} to="/">
        <img src={cyboidlogo} alt="Cyboid Technologies Logo" />
      </Logo>
      <HamburgerIcon onClick={toggleNav}>
        {isNavOpen ? <FaTimes /> : <FaBars />}
      </HamburgerIcon>
      <Nav $isOpen={isNavOpen}>
        <ul>
          <li>
            <StyledNavLink to="/" onClick={closeNav}>
              Home
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink to="/about-us" onClick={closeNav}>
              About Us
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink to="/products" onClick={closeNav}>
              Products
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink to="/contact-us" onClick={closeNav}>
              Contact
            </StyledNavLink>
          </li>
        </ul>
      </Nav>
      <QuoteButton as="a" href="tel:+91-9043413318">
        {/* <FaPhoneVolume /> */}
        {/* <MdOutlinePhoneInTalk /> */}
        <FontAwesomeIcon icon={faPhoneVolume} />
        +91-9043413318
      </QuoteButton>
    </StyledHeader>
  );
};

export default Header;
