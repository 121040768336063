import { useState } from "react";
import About from "../components/About";
import CallToAction from "../components/CallToAction";
import FaqSection from "../components/FaqSection";
import LogoSlider from "../components/LogoSlider";
import PageTitleArea from "../components/PageTitleArea";
import VideoPlayer from "../components/VideoPlayer";
import styled from "styled-components";
import videoPlay from "../assets/Cyboid Intro.mp4";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  padding: 2rem;
  border-radius: 8px;
  max-width: 900px;
  width: 90%;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

function AboutPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   AOS.init({ duration: 1000 });
  // }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
      <PageTitleArea title="About Us" breadcrumb="About Us" condition={false} />
      <VideoPlayer openModal={openModal} btn={false} />
      <About />

      {/* <FaqSection /> */}
      <LogoSlider />
      <CallToAction />

      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            {/* <CloseButton onClick={closeModal}>&times;</CloseButton> */}
            <StyledVideo controls muted autoPlay>
              <source src={videoPlay} type="video/mp4" />
              Your browser does not support the video tag.
            </StyledVideo>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
}

export default AboutPage;
