import React from "react";
import PageTitleArea from "../components/PageTitleArea";
import ContactForm from "../components/ContactForm";

const ContactUsPage = () => {
  return (
    <>
      <PageTitleArea
        title="Contact Us "
        breadcrumb="Contact Us"
        condition={false}
      />
      <ContactForm />
    </>
  );
};
export default ContactUsPage;
