import React from "react";
import ProductPage from "./ProductSection";
import storage from "../assets/images/banner/software.png";
import logo1 from "../assets/brand/Adobe.png";
import logo2 from "../assets/brand/Microsoft.png";
import PageTitleArea from "../components/PageTitleArea";

const SoftwareProduct = () => {
  const storageImages = [
    { src: logo1, alt: "TechCrunch Logo 1" },
    { src: logo2, alt: "TechCrunch Logo 2" },
  ];

  return (
    <>
      <PageTitleArea
        title="Software"
        breadcrumb="Software"
        products="Our Products"
        condition={false}
      />
      <ProductPage
        subtitle="Software"
        title="Software comprises programs and applications that enable computers and devices to perform specific tasks or functions, ranging from word processing and data analysis to multimedia editing and system maintenance, driving productivity and facilitating various digital activities."
        images={storageImages}
        backgroundImage={storage}
      />
    </>
  );
};

export default SoftwareProduct;
