import React from "react";
import { Outlet } from "react-router-dom";
import CallToAction from "../components/CallToAction";

const ProductsLayout = () => {
  return (
    <>
      <Outlet />
      <CallToAction />
    </>
  );
};

export default ProductsLayout;
