import React from "react";
import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg) scale(0.6); }
  50% { transform: rotate(100deg) scale(0.4); }
  100% { transform: rotate(360deg) scale(0.6); }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
  height: 100vh;
  background-color: rgb(21, 21, 21);
  color: white;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 0px;
  width: 50px;
  height: 50px;
  animation: ${rotateAnimation} 2s linear infinite;
`;

const GridItem = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({ color }) => color};
  margin: auto;
`;

const LoaderText = styled.h3`
  margin-left: 10px;
  font-family: "Belanosima", sans-serif;
`;

const Loader = () => {
  return (
    <LoaderWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <GridContainer>
          <GridItem color="white" />
          <GridItem color="#4b2999" />
          <GridItem color="white" />
          <GridItem color="white" />
        </GridContainer>
        <LoaderText>Cyboid Technologies</LoaderText>
      </div>
    </LoaderWrapper>
  );
};

export default Loader;
