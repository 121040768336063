import React, { useEffect } from "react";
import styled from "styled-components";
// import teamImage from "../assets/images/banner/about-us-img.jpg";
import teamImage from "../assets/images/banner/about.png";
import videoImage from "../assets/images/video image.png";
import videoIcon from "../assets/images/video-icon-2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import ExperienceBadge from "./ExperienceBadge";
import CounterSection from "./Counter";
import { Link } from "react-router-dom";

const SectionContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 8rem;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-bottom: 8rem;
  gap: 2rem;
  background-color: #fafaff;
  /* margin-bottom: 40px; */

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 6rem 1rem;
    align-items: center;
    text-align: center;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 6rem 1rem;
    align-items: center;
    text-align: center;
  }
`;

const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  width: 100%;
  max-width: 400px;

  @media (max-width: 768px) {
    max-width: 100%;
    /* gap: 0.2rem; */
  }
`;

const LargeImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SmallImageContainer = styled.div`
  position: relative;
  top: -226px;
  left: -50px;
  width: 250px;
  height: 250px;
  border-top: 10px solid white;
  border-right: 10px solid white;
  cursor: pointer;

  @media (max-width: 768px) {
    top: -260px;
    left: 0;
    width: 70%;
    /* height: 300px; */
    /* max-width: 200px; */
    max-width: 400px;
  }
  @media (max-width: 1024px) {
    top: -220px;
    left: 0;
    width: 70%;
    /* height: 300px; */
    /* max-width: 200px; */
    max-width: 300px;
  }
`;

const VideoThumbnail = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
  }
`;

const BadgeContainer = styled.div`
  position: absolute;
  /* top: 145px; */
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  @media (max-width: 480px) {
    left: 180px;
    top: 180px;
  }

  @media (max-width: 1024px) {
    top: 140px;
    left: 250px;
    left: 185px;
    top: 185px;
  }
`;

const TextContent = styled.div`
  flex: 1;
  padding: 0 1rem;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    padding: 0;
  }
`;

const TextContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0 1rem;
    max-width: 100%;
    /* text-align: justify; */
  }
`;

const AboutText = styled.h4`
  color: #4b2999;
  font-size: 16px;
  margin-bottom: 1rem;
`;

const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  text-align: justify;

  @media (max-width: 768px) {
    text-align: justify;
  }
`;

const ActionButton = styled.button`
  background-image: linear-gradient(120deg, #4b2999 0%, #57eef1 100%);
  padding: 0.8rem 1.5rem;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VideoPlayer = ({ openModal, btn }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <SectionContainer>
      <ImageContent data-aos="fade-right">
        <BadgeContainer>
          <ExperienceBadge />
        </BadgeContainer>
        <LargeImage src={teamImage} alt="Team" />
        {/* <SmallImageContainer onClick={openModal}>
          <VideoThumbnail src={videoImage} alt="Video Thumbnail" />
          <PlayButton>
            <img src={videoIcon} alt="Play Icon" />
          </PlayButton>
        </SmallImageContainer> */}
      </ImageContent>

      <TextContainer data-aos="fade-left">
        <AboutText>About Us</AboutText>
        <Heading>Provides Best Approach To Security</Heading>
        <Description>
          Cyboid Technologies is a cutting-edge technology company dedicated to
          providing innovative solutions in software development, cybersecurity,
          artificial intelligence (AI), machine learning, and Internet of Things
          (IoT).
        </Description>
        <CounterSection />
        {btn && (
          <ActionButton as={Link} to="/about-us">
            Learn More
          </ActionButton>
        )}
      </TextContainer>
    </SectionContainer>
  );
};

export default VideoPlayer;
