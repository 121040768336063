import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { createRecord } from "../hooks/createRecord";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Styled Components
const SectionContainer = styled.section`
  background-color: #0d1422;
  padding: 80px 20px;
  text-align: center;
  color: white;

  @media (max-width: 768px) {
    padding: 50px 10px;
  }

  @media (max-width: 480px) {
    padding: 40px 5px;
  }
`;

const FormTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const SubTitle = styled.p`
  font-size: 1.2rem;
  color: #57eef1;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    margin-bottom: 30px;
  }
`;

const FormContainer = styled.form`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    gap: 15px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const InputField = styled.input`
  flex: 1;
  padding: 15px;
  background-color: #111929;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  outline: none;
  /* width: 100%;  */
  width: 254px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  &:focus {
    outline: 2px solid #57eef1;
  }

  @media (max-width: 480px) {
    padding: 12px;
    font-size: 0.9rem;
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 12px;
    font-size: 0.9rem;
    width: 100%;
  }
`;

const MessageField = styled.textarea`
  padding: 15px;
  background-color: #111929;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  outline: none;
  resize: none;
  min-height: 150px;
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  &:focus {
    outline: 2px solid #57eef1;
  }

  @media (max-width: 480px) {
    padding: 12px;
    font-size: 0.9rem;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 30px;
  background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px;

  &:hover {
    background: linear-gradient(60deg, #57eef1 0%, #4b2999 100%);
  }

  @media (max-width: 480px) {
    padding: 8px 25px;
    font-size: 0.9rem;
  }
`;

const GetInTouchForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const createdRecord = await createRecord(data);
      toast.success("Form submitted successfully!");
    } catch {
      toast.error("Issue in submit form");
    } finally {
      reset();
    }
  };

  return (
    <SectionContainer>
      <ToastContainer />
      <SubTitle>Get in Touch</SubTitle>
      <FormTitle>Send us a Message</FormTitle>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <div>
            <InputField
              type="text"
              placeholder="Name"
              {...register("name", { required: "Name is required" })}
            />
            {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
          </div>
          <div>
            <InputField
              type="text"
              placeholder="Phone"
              {...register("phone", { required: "Phone is required" })}
            />
            {errors.phone && (
              <ErrorMessage>{errors.phone.message}</ErrorMessage>
            )}
          </div>
          <div>
            <InputField
              type="email"
              placeholder="Email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </div>
        </InputGroup>
        <div style={{ width: "100%" }}>
          <MessageField
            placeholder="Message"
            {...register("message", { required: "Message is required" })}
          />
          {errors.message && (
            <ErrorMessage>{errors.message.message}</ErrorMessage>
          )}
        </div>
        <SubmitButton type="submit">Submit Now</SubmitButton>
      </FormContainer>
    </SectionContainer>
  );
};

export default GetInTouchForm;
