import "./App.css";

import Header from "./components/Header";

import "tailwindcss/tailwind.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./Pages/HomePage";
import GetInTouchForm from "./components/GetInTouchForm";
import Services from "./Pages/Services";
import ContactUsPage from "./Pages/ContactUsPage";
import FirewallProduct from "./products/FirewallProduct";
import ProductsLayout from "./productLayout/ProductsLayout";
import ScrollToTop from "./hooks/ScrollToTop";
import ScrollToTopButton from "./hooks/ScrollToTopButton";
import Loader from "./components/Loader";
import { useEffect, useState } from "react";
import { VideoProvider } from "./context/VideoContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutPage from "./Pages/AboutPage";
import StorageProduct from "./products/StorageProduct";
import EndPointProduct from "./products/EndPointProduct";
import WirelessProduct from "./products/WirelessProduct";
import ServerProduct from "./products/ServerProduct";
import SoftwareProduct from "./products/SoftwareProduct";
import Networking from "./products/Networking";
import VideoConferenece from "./products/VideoConferenece";
import IbmAirwatchProduct from "./products/IbmAirwatchProduct";
import NetworkingCablingProduct from "./products/NetworkCablingProduct";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <VideoProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="about-us" element={<AboutPage />} />
            <Route path="products" element={<ProductsLayout />}>
              <Route index element={<Services />} />
              <Route path="firewall" element={<FirewallProduct />} />
              <Route path="storage" element={<StorageProduct />} />
              <Route path="end-point-security" element={<EndPointProduct />} />
              <Route path="wireless" element={<WirelessProduct />} />
              <Route path="Server" element={<ServerProduct />} />
              <Route path="software" element={<SoftwareProduct />} />
              <Route path="networking" element={<Networking />} />
              <Route path="video-conference" element={<VideoConferenece />} />
              <Route path="ibm-airwatch" element={<IbmAirwatchProduct />} />
              <Route
                path="network-cabling"
                element={<NetworkingCablingProduct />}
              />
              {/* <Route path="ibm-airwatch" element={<WirelessProduct/>} /> */}
            </Route>
            <Route path="contact-us" element={<ContactUsPage />} />
          </Route>
        </Routes>
        <ScrollToTopButton />
      </Router>
    </VideoProvider>
  );
}

export default App;
