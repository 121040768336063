import React from "react";
import firewall from "../assets/images/video-con.jpg";
import logo1 from "../assets/brand/Cisco.png";
import logo2 from "../assets/brand/Polycom.png";
import logo3 from "../assets/brand/Lifesize.png";
// import logo4 from "../assets/images/techcrunch.png";
import PageTitleArea from "../components/PageTitleArea";
import ProductSection from "./ProductSection";

const VideoConferenece = () => {
  const images = [
    { src: logo1, alt: "Cisco" },
    { src: logo2, alt: "PolyCom" },
    { src: logo3, alt: "LifeSize" },
    // { src: logo4, alt: "TechCrunch Logo 4" },
  ];

  return (
    <>
      <PageTitleArea
        title="Video Conference"
        breadcrumb="Video Conference"
        products="Our Products"
        condition={false}
      />
      <ProductSection
        subtitle="Video Conference"
        title="Video conferencing enables real-time, face-to-face communication between individuals or groups regardless of geographical locations, fostering collaboration, enhancing productivity, and reducing the need for travel, thus saving time and resources while promoting effective communication and decision-making."
        images={images}
        backgroundImage={firewall}
      />
    </>
  );
};

export default VideoConferenece;
