import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes, css } from "styled-components";

const rotateOnce = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const BadgeContainer = styled.div`
  width: 100px;
  height: 100px;
  background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;

  border: 5px solid white;

  ${(props) =>
    props.animate &&
    css`
      animation: ${rotateOnce} 2s ease-in-out;
    `}
`;
// const BadgeContainer = styled.div`
//   width: 100px;
//   height: 100px;
//   background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
//   border-radius: 50%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   font-weight: bold;
//   font-size: 1rem;
//   text-align: center;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

//   ${(props) =>
//     props.animate &&
//     css`
//       animation: ${rotateOnce} 2s ease-in-out;
//     `}
// `;

const BadgeText = styled.p`
  margin: 0;
  font-size: 0.8rem;
`;

const ExperienceBadge = () => {
  const [inView, setInView] = useState(false);
  const [count, setCount] = useState(0);
  const badgeRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setInView(true);
        }
      },
      { threshold: 0.5 }
    );

    if (badgeRef.current) {
      observer.observe(badgeRef.current);
    }

    return () => {
      if (badgeRef.current) {
        observer.unobserve(badgeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (inView && count < 5) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount < 5) {
            return prevCount + 1;
          } else {
            clearInterval(intervalId);
            return 5;
          }
        });
      }, 1200);

      return () => clearInterval(intervalId);
    }
  }, [inView, count]);

  return (
    <BadgeContainer ref={badgeRef} animate={inView}>
      {count}+<BadgeText>Years of</BadgeText>
      <BadgeText>Experience</BadgeText>
    </BadgeContainer>
  );
};

export default ExperienceBadge;
