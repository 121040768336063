import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import serviceimage from "../assets/images/services-1.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { GiFirewall } from "react-icons/gi";
import { FaDatabase, FaServer, FaWifi, FaVideo } from "react-icons/fa";
import {
  MdStorage,
  MdSecurity,
  MdComputer,
  MdCable,
  MdNetworkPing,
} from "react-icons/md";

const CarouselWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  .slick-dots {
    bottom: -35px;
  }

  .slick-dots li {
    margin: 0 8px;
  }

  .slick-dots li button:before {
    font-size: 10px;
    width: 20px;
    height: 5px;
    content: "";
    background-color: black;
    display: block;
    transition: all 0.3s ease;
    border-radius: 2px;
  }

  .slick-dots li.slick-active button:before {
    background-color: #0b3b4c;
    transform: scale(1.2);
  }
`;

const CarouselItem = styled.div`
  position: relative;
  width: 270px;
  height: 400px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }

  &:hover .services-content {
    bottom: 0px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.5s ease;
`;

const ServicesContent = styled.div`
  position: absolute;
  bottom: -120px;
  left: 0;
  right: 0;
  padding: 30px;
  transition: all 0.5s ease;
  color: white;
  text-align: left;
`;

const Heading = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left;
  &:hover {
    color: red;
  }
`;

const DescriptionText = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
`;

const ReadMore = styled.a`
  display: inline-block;
  color: red;
  cursor: pointer;
  text-align: left;
  margin-top: 10px;
  left: 29px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.2em;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;

  span {
    display: block;
    font-size: 0.7em;
    font-weight: 600;
    color: #ff4b5c;
    margin-bottom: 10px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
  border-radius: 50%;
  background-color: aliceblue;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;

  svg {
    width: 50px;
    height: 50px;
    color: white;
  }
`;

const CarouselCard = ({ imageSrc, title, description, link }) => (
  <>
    <CarouselItem>
      <Image src={imageSrc} alt={title} />
      <Overlay>
        <ServicesContent className="services-content">
          <Heading>{title}</Heading>
          <DescriptionText>{description}</DescriptionText>
          <ReadMore as={Link} to={link}>
            Read More →
          </ReadMore>
        </ServicesContent>
      </Overlay>
    </CarouselItem>
  </>
);

const Carousel = () => {
  const slides = [
    {
      imageSrc: serviceimage,
      title: "Firewall",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do.",
      link: "/products/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "Storage",
      description: "Protect your site from malicious malware and threats.",
      link: "/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "Endpoint Security",
      description:
        "Secure your cloud infrastructure with our cutting-edge solutions.",
      link: "/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "Wireless",
      description: "Ensure your data is protected and secure.",
      link: "/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "IBM Airwatch",
      description: "Secure backup solutions for your critical data.",
      link: "/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "Video Conferencing",
      description: "Improve your website's speed and performance.",
      link: "/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "Networking",
      description: "Protect your website with SSL encryption.",
      link: "/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "Software",
      description:
        "Protect your site from distributed denial of service attacks.",
      link: "/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "Server",
      description:
        "Protect your site from distributed denial of service attacks.",
      link: "/firewall",
    },
    {
      imageSrc: serviceimage,
      title: "Networking Cabling",
      description:
        "Protect your site from distributed denial of service attacks.",
      link: "/firewall",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Title>
        <span>Our Products</span>
      </Title>

      <CarouselWrapper>
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <CarouselCard
              key={index}
              imageSrc={slide.imageSrc}
              title={slide.title}
              description={slide.description}
              link={slide.link}
            />
          ))}
        </Slider>
      </CarouselWrapper>
    </>
  );
};

export default Carousel;
