import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const CounterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  padding: 20px;
  border-left: 1px solid #ddd;

  &:first-child {
    border-left: none; /* No border for the first item */
  }
`;

const CounterText = styled.h2`
  font-size: 30px;
  font-weight: bold;
  line-height: 1.1em;
  margin: 0;
`;

const CounterTitle = styled.p`
  font-size: 1rem;
  color: #7c7c7c;
  text-align: center;
  margin-top: 10px;
  @media (max-width: 768px) {
    font-size: 0.5rem;
  }
`;

const CounterContainer = styled.div`
  margin-bottom: 30px !important;
  display: flex;
  justify-content: space-around;
  max-width: 800px;
  margin: 0 auto;
  /* padding: 40px 0; */
  /* border: 1px solid #eee; */
`;

const Counter = ({ target, text }) => {
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasStarted) {
            setHasStarted(true);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [hasStarted]);

  useEffect(() => {
    let intervalId;

    const updateCounter = () => {
      const increment = target / 100;
      if (count < target) {
        setCount((prevCount) => {
          const newCount = prevCount + increment;
          return newCount < target ? newCount : target;
        });
      } else {
        clearInterval(intervalId);
      }
    };

    if (hasStarted) {
      intervalId = setInterval(updateCounter, 50);
    }

    return () => clearInterval(intervalId);
  }, [count, target, hasStarted]);

  return (
    <CounterBox ref={counterRef}>
      <CounterText>{Math.floor(count)}+</CounterText>
      <CounterTitle>{text}</CounterTitle>
    </CounterBox>
  );
};

const CounterSection = () => {
  return (
    <CounterContainer>
      <Counter target={300} text="Happy Clients" />
      <Counter target={300} text=" Projects" />
      <Counter target={40} text="Experts Team" />
    </CounterContainer>
  );
};

export default CounterSection;
