import React, { useState, useEffect } from "react";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import styled from "styled-components";

const GoTopButton = styled.div`
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  background-color: #e21f36;
  background: linear-gradient(120deg, #4b2999, #57eef1);
  z-index: 999;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: 0.9s;

  i {
    color: #fff;
    font-size: 20px;
    transition: 0.5s;
    position: absolute;
    top: 10px;
  }

  i:last-child {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }

  &:hover i:first-child {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }

  &:hover i:last-child {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &:hover {
    background-color: #ff0000;
  }
`;

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <GoTopButton
      onClick={scrollToTop}
      style={{ display: isVisible ? "flex" : "none" }}
    >
      <i>
        <MdKeyboardDoubleArrowUp />
      </i>
      <i>
        <MdKeyboardDoubleArrowUp />
      </i>
    </GoTopButton>
  );
};

export default ScrollToTopButton;
