import React from "react";
import ProductPage from "./ProductSection";
import storage from "../assets/images/banner/server.jpg";
import logo1 from "../assets/brand/dell.png";
import logo2 from "../assets/brand/ibm.png";
import logo3 from "../assets/brand/hp.png";

import PageTitleArea from "../components/PageTitleArea";

const ServerProduct = () => {
  const storageImages = [
    { src: logo1, alt: "dell" },
    { src: logo2, alt: "Ibm" },
    { src: logo3, alt: "hp" },
  ];

  return (
    <>
      <PageTitleArea
        title="Servers"
        breadcrumb="Servers"
        products="Our Products"
        condition={false}
      />
      <ProductPage
        subtitle="Servers"
        title="Servers are powerful computers designed to store, process, and manage data, applications, and services across networks, facilitating centralized access to resources and enabling efficient communication and collaboration among users and devices."
        images={storageImages}
        backgroundImage={storage}
      />
    </>
  );
};

export default ServerProduct;
