import React from "react";
import firewall from "../assets/images/banner/endpoint.png";
// import logo1 from "../assets/images/techcrunch.png";
import logo2 from "../assets/brand/Websense.png";
import logo1 from "../assets/brand/Trendmicro.png";
import logo3 from "../assets/brand/McAfee.png";
import logo4 from "../assets/brand/symentec.png";
import logo5 from "../assets/brand/Kaspersky.png";
import PageTitleArea from "../components/PageTitleArea";
import ProductSection from "./ProductSection";

const EndPointProduct = () => {
  const images = [
    { src: logo1, alt: "TechCrunch Logo 1" },
    { src: logo2, alt: "TechCrunch Logo 2" },
    { src: logo3, alt: "TechCrunch Logo 3" },
    { src: logo4, alt: "TechCrunch Logo 4" },
    { src: logo5, alt: "TechCrunch Logo 4" },
  ];

  return (
    <>
      <PageTitleArea
        title="End Point Security"
        breadcrumb="End Point Security"
        condition={false}
        products="Our Products"
      />
      <ProductSection
        subtitle="End-point Security"
        title="Focuses on protecting individual devices such as computers, laptops, and mobile devices from cyber threats by deploying security measures like antivirus software, firewalls, and intrusion detection systems, safeguarding data and preventing unauthorized access or compromise."
        images={images}
        backgroundImage={firewall}
      />
    </>
  );
};

export default EndPointProduct;
