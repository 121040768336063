import React from "react";
import styled from "styled-components";
import ImageGrid from "./ImageGrid";

const SectionWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  /* flex-direction: column-reverse; */
  gap: 20px;
  align-items: center;
  padding: 50px;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const LeftContent = styled.div`
  flex: 1;
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-right: 0;
    text-align: center;
  }
`;

const Subtitle = styled.p`
  font-size: 36px;
  color: #e21f36;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Title = styled.p`
  font-size: 20px;
  color: #222;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Para = styled.p`
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const RightImageWrapper = styled.div`
  flex: 1;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  height: 350px;

  @media (max-width: 768px) {
    flex: none;
    height: 300px;
    width: 100%;
    margin-top: 20px;
  }
`;

const FeatureList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin-top: -20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: -0px;
  }
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;

  &:before {
    content: "✔";
    color: #4caf50;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ProductSection = ({
  subtitle,
  title,
  description,
  images,
  backgroundImage,
  features,
}) => {
  return (
    <SectionWrapper>
      <RightImageWrapper backgroundImage={backgroundImage} />
      <LeftContent>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
        <Para>{description}</Para>
        <FeatureList>
          {features &&
            features.map((feature, index) => (
              <FeatureItem key={index}>{feature}</FeatureItem>
            ))}
        </FeatureList>
        {images && <ImageGrid images={images} />}
      </LeftContent>
    </SectionWrapper>
  );
};

export default ProductSection;
