import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GiFirewall } from "react-icons/gi";
import { FaDatabase, FaServer, FaWifi, FaVideo } from "react-icons/fa";
import {
  MdStorage,
  MdSecurity,
  MdComputer,
  MdCable,
  MdNetworkPing,
} from "react-icons/md";

import firewall from "../assets/images/firewall.jpg";
import storage from "../assets/images/storage.jpg";
import endpoint from "../assets/images/banner/endpoint.png";
import wireless from "../assets/images/banner/wireless.jpg";
import ibmairwatch from "../assets/images/vmware.png";
import networking from "../assets/images/banner/networking.jpg";
import networkcable from "../assets/images/banner/networkcable.jpg";
import software from "../assets/images/banner/software.png";
import server from "../assets/images/banner/server.jpg";
import videoConferenece from "../assets/images/video-con.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

const Section = styled.section`
  margin-top: 40px;
  padding: 50px 20px;
  text-align: center;
`;

const SectionTitle = styled.h2`
  margin-top: 35px;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ServiceCard = styled.div`
  overflow: hidden;
  width: 300px;
  height: auto;
  transition: transform 0.3s ease;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: visible;

  img {
    width: 100%;
    height: 200px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
  border-radius: 50%;
  background-color: aliceblue;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;

  svg {
    width: 50px;
    height: 50px;
    color: white;
  }
`;

const Content = styled.div`
  padding: 20px;
  text-align: center;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  color: #333;
  margin: 20px 0 10px 0;
`;

const Description = styled.p`
  color: #666;
  font-size: 0.9rem;
  text-align: center;
`;

const ReadMore = styled.a`
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  color: #4b2999;
  text-decoration: none;

  &:hover {
    color: #57eef1;
  }
`;

const servicesData = [
  {
    title: "Firewall",
    description:
      "Firewalls are digital barriers that monitor and control incoming and outgoing....",
    image: firewall,
    icon: <GiFirewall />,
    link: "/products/firewall",
  },
  {
    title: "Storage",
    description:
      "Storage is the process of storing, managing, and preserving digital data....",
    image: storage,
    icon: <FaDatabase />,
    link: "/products/storage",
  },
  {
    title: "End Point Security",
    description:
      "Focuses on protecting individual devices such as computers, laptops...",
    image: endpoint,
    icon: <MdSecurity />,
    link: "/products/end-point-security",
  },
  {
    title: "Wireless",
    description: "Wireless technology enables the transmission of data and...",
    image: wireless,
    icon: <FaWifi />,
    link: "/products/wireless",
  },
  {
    title: "IBM Airwatch",
    description:
      "This is now known as VMware Workspace ONE, is an enterprise...",
    image: ibmairwatch,
    icon: <MdStorage />,
    link: "/products/ibm-airwatch",
  },
  {
    title: "Video Conference",
    description:
      "Video conferencing enables real-time, face-to-face communication between...",
    image: videoConferenece,
    icon: <FaVideo />,
    link: "/products/video-conference",
  },
  {
    title: "Networking",
    description:
      "Involves the interconnection of computers and other devices...",
    image: networking,
    icon: <MdNetworkPing />,
    link: "/products/networking",
  },
  {
    title: "Software",
    description: "Software comprises programs and applications that enable...",
    image: software,
    icon: <MdComputer />,
    link: "/products/software",
  },
  {
    title: "Server",
    description: "Servers are powerful computers designed to store, process...",
    image: server,
    icon: <FaServer />,
    link: "/products/server",
  },
  {
    title: "Network Cabling",
    description:
      "Network cabling involves the installation of physical cables...",
    image: networkcable,
    icon: <MdCable />,
    link: "/products/network-cabling",
  },
];

const ServicesSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Section>
      <SectionTitle>
        Protect Your Business With Our Unique Solutions
      </SectionTitle>
      <ServicesWrapper>
        {servicesData.map((service, index) => (
          <ServiceCard key={index} data-aos="fade-up">
            <ImageWrapper>
              <img src={service.image} alt={service.title} />
              <IconWrapper>{service.icon}</IconWrapper>
            </ImageWrapper>
            <Content>
              <Title>{service.title}</Title>
              <Description>{service.description}</Description>
              {service.link && (
                <ReadMore as={Link} to={service.link}>
                  Read More &gt;
                </ReadMore>
              )}
            </Content>
          </ServiceCard>
        ))}
      </ServicesWrapper>
    </Section>
  );
};

export default ServicesSection;
