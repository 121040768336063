import React, { useEffect } from "react";
import styled from "styled-components";
import backgroundImg from "../assets/images/banner/CallToAction.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Button = styled.button`
  display: inline-block;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0;
  padding: 0 50px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 0px;
  color: #1d1729;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-out;
  background-color: #fff;
  position: relative;
  z-index: 10;
  overflow: hidden;
  letter-spacing: 1.5px;

  &::before {
    position: absolute;
    content: "";
    background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
    width: 100%;
    height: 0%;
    left: 50%;
    top: 50%;
    border-radius: 33px;
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: -1;
    transition: all 500ms ease;
    opacity: 1;
  }

  &:hover {
    background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
    color: #fff;
    border-color: #5f2dee;
  }

  &:hover::before {
    height: 400px;
    opacity: 0;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 50px;
    padding: 0 30px;
  }
`;

const CTASection = styled.section`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  padding: 0 30px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0e1625;
    z-index: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    height: auto;
    padding: 20px;
  }
`;

const CTAText = styled.div`
  flex: 1;
  font-size: 1.5rem;
  z-index: 2;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
  }

  p {
    margin-top: 10px;
    font-size: 1.2rem;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1rem;
    }

    margin-bottom: 20px;
  }
`;

const CallToAction = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <CTASection data-aos="fade-left">
      <CTAText>
        <p>Ready to take your business to the next level?</p>
        <h1>Get in touch with our team today!</h1>
      </CTAText>
      <Button as={Link} to="/contact">
        LET'S TALK
      </Button>
    </CTASection>
  );
};

export default CallToAction;
