import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import cyboidlogo from "../assets/images/Cyboid logo trs.png";
import { Link, NavLink } from "react-router-dom";

// Styled Components
const FooterContainer = styled.footer`
  background-color: #0e1625;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding: 20px;
  padding-top: 0px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

const Logo = styled.div`
  padding: 2rem;
  font-size: 1.5rem;

  img {
    width: 150px;
    height: auto;
    /* max-width: 100%; */
    transition: transform 0.3s ease;
    @media (max-width: 768px) {
      margin: 0 auto;
      display: none;
      /* padding-left: rem; */
    }
  }
`;

const CompanyInfo = styled.div`
  background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);

  padding: 30px;
  color: white;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: bold;
  }

  p {
    margin: 5px 0;
    line-height: 1.5;

    i {
      margin-right: 10px;
    }
  }

  @media (max-width: 767px) {
    grid-column: span 2;
    text-align: center;
  }
`;

const FooterLinksSection = styled.div`
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      /* border-radius: 8px; */
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      a {
        color: white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const FooterSubscribe = styled.div`
  padding-top: 40px;
  @media (max-width: 767px) {
    padding-left: 100px;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
  }

  form {
    display: flex;
    input {
      padding: 10px;
      border-radius: 5px;
      border: none;
      flex: 1;
    }

    button {
      background: linear-gradient(60deg, #4b2999 0%, #57eef1 100%);
      padding: 10px;
      border: none;
      border-radius: 5px;
      color: white;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .social-media {
    margin-top: 20px;
    margin-left: 25px;
    display: flex;
    gap: 10px;
    @media (max-width: 767px) {
      font-size: 0.9rem;
      margin-left: 0px;
    }

    a {
      color: white;
      font-size: 1.5rem;
      text-decoration: none;
    }
  }
`;

//
const FooterBottom = styled.div`
  text-align: center;
  color: white;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <FooterContainer>
      <FooterContent>
        {/* Company Info */}
        <CompanyInfo>
          <h2 style={{ textAlign: "center", fontSize: "17px" }}>
            CYBOID TECHNOLOGIES
          </h2>
          <p
            style={{
              textAlign: "center",
              marginTop: "-25px",
              marginBottom: "20px",
              fontSize: "12px",
            }}
          >
            Your Tech Ally
          </p>
          <p>
            <i className="fas fa-phone"></i> +91 8939056056
          </p>
          <p>
            <i className="fas fa-envelope"></i> kenet@cyboid.in
          </p>
          <p>
            <i className="fas fa-map-marker-alt"></i> 6/13 North Avenue,
            Kesavaperumalpuram, <br />
            Raja Annamalai Puram, Chennai, TamilNadu - 600028
          </p>
        </CompanyInfo>

        {/* Navigation Links */}
        <FooterLinksSection>
          <div>
            <h3>Navigation</h3>
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/products">Products</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>
        </FooterLinksSection>

        <FooterLinksSection>
          <div>
            <h3>Quick Links</h3>
            <ul>
              <li>
                <NavLink to="/products/firewall">Firewall</NavLink>
              </li>
              <li>
                <NavLink to="/products/storage">Storage</NavLink>
              </li>
              <li>
                <NavLink to="/products/wireless">Wireless</NavLink>
              </li>
              <li>
                <NavLink to="/products/networking">Networking</NavLink>
              </li>
            </ul>
          </div>
        </FooterLinksSection>

        {/* Newsletter */}
        <FooterSubscribe>
          {/* <h3>Subscribe to our Newsletter</h3>
          <form>
            <input type="email" placeholder="Your email" />
            <button type="submit">Subscribe</button>    width: 150px;
    height: auto;
          </form> */}
          {/* <Logo as={Link} to="/"> */}
          <img
            src={cyboidlogo}
            alt="Cyboid Technologies Logo"
            style={{ width: "150px", height: "auto" }}
          />
          {/* </Logo> */}
          <br />
          {/* <Follow>Follow on social media:</Follow> */}
          <div className="social-media">
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </FooterSubscribe>
      </FooterContent>

      <FooterBottom>
        &copy; {new Date().getFullYear()} Cyboid Technologies. All rights
        reserved.
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
