import { useEffect, useState } from "react";
import AboutUs from "../components/AboutUs";
import Carousel from "../components/Carousel";
import CounterWrapper from "../components/Counter";
import GetInTouchForm from "../components/GetInTouchForm";
import HeroSection from "../components/HeroSection";
import VideoPlayer from "../components/VideoPlayer";
import WhyChooseUs from "../components/WhyChooseUs";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";

import videoPlay from "../assets/Cyboid Intro.mp4";
import WhatWeDoSection from "../components/WhatWeDoSection";
import ServiceCarousel from "../components/ServiceCarousel";
import FaqSection from "../components/FaqSection";
import LogoSlider from "../components/LogoSlider";

const HomePage = ({ onVideoReady }) => {
  const [activeTab, setActiveTab] = useState("aboutus");

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <HeroSection />

      <VideoPlayer openModal={openModal} btn={true} />
      {/* <AboutUs activeTab={activeTab} setActiveTab={setActiveTab} /> */}
      {/* </div> */}
      {/* </div> */}

      <WhatWeDoSection />
      <div
        className="text-center pb-20 px-4 pt-5  relative"
        style={{ backgroundColor: "#fafaff" }}
      >
        <div className="absolute inset-0 bg-cover bg-center opacity-20"></div>
        <h1
          className="text-3xl md:text-4xl font-bold mb-8"
          style={{ color: ": #4B2999" }}
          data-aos="zoom-in-up"
        >
          Our Products
        </h1>
        <div style={{ paddingBottom: "40px" }}>
          <ServiceCarousel />
        </div>
      </div>

      <GetInTouchForm />
      {/* <FaqSection /> */}
      <LogoSlider />
      {/* <CounterWrapper /> */}

      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            {/* <CloseButton onClick={closeModal}>&times;</CloseButton> */}
            <StyledVideo controls muted autoPlay>
              <source src={videoPlay} type="video/mp4" />
              Your browser does not support the video tag.
            </StyledVideo>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default HomePage;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  padding: 2rem;
  border-radius: 8px;
  max-width: 900px;
  width: 90%;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;
