import { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { FaArrowsAltH } from "react-icons/fa";
import paloato from "../assets/brand/Paloalto.png";
import kaspersky from "../assets/brand/Kaspersky.png";
import mca from "../assets/brand/McAfee.png";
import Trendmicro from "../assets/brand/Trendmicro.png";
import Check_Point from "../assets/brand/Check_Point.png";
import fortinet from "../assets/brand/fortinet.png";
import Cisco from "../assets/brand/Cisco.png";
import cyberoam from "../assets/brand/cyberoam.png";
import sophos from "../assets/brand/sophos.png";
import SonicWall from "../assets/brand/SonicWall.png";
import AOS from "aos";
import "aos/dist/aos.css";

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const scrollY = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

const fade = keyframes`
  to {
    opacity: 0;
    visibility: hidden;
  }
`;

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  max-width: 100vw;
  padding-top: 30px;
  padding-bottom: 80px;

  &.wrapper--vertical {
    flex-direction: row;
    height: 100vh;
  }
`;

const ToggleButton = styled.button`
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 3rem;
  height: 3rem;
  font: inherit;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 50%;
  color: navy;
  background-color: #ecdcc0;
  z-index: 1;

  &:focus-visible {
    box-shadow: 0 0 0 2px navy;
  }

  span {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: calc(100% + 0.4em);
    width: fit-content;
    white-space: nowrap;
    transform: translateY(-50%);
    animation: ${fade} 400ms 4s ease-out forwards;
    user-select: none;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5rem;
    height: 1.5rem;
    fill: currentcolor;
    transform: translate(-50%, -50%);
    transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);

    &.toggle--vertical {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
`;

const StyledMarquee = styled.div`
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 1rem;
  mask-image: linear-gradient(
    ${({ direction }) => (direction === "vertical" ? "to bottom" : "to right")},
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );

  &.marquee--vertical {
    flex-direction: column;
  }

  &.marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: -3s;
  }
`;

const MarqueeGroup = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  min-width: 100%;
  animation: ${({ direction }) =>
    direction === "vertical"
      ? css`
          ${scrollY} 60s linear infinite
        `
      : css`
          ${scrollX} 60s linear infinite
        `};
`;

const StyledImage = styled.img`
  display: grid;
  place-items: center;
  width: 10rem;
  background: papayawhip;
  background: #fafaff;
  aspect-ratio: 16/9;
  padding: 1rem;
  border-radius: 0.5rem;
`;

const LogoSlider = () => {
  const [isVertical, setIsVertical] = useState(false);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const toggleDirection = () => {
    setIsVertical(!isVertical);
  };

  return (
    <>
      <h1
        className="text-3xl md:text-4xl font-bold mb-8"
        style={{ color: ": #4B2999", textAlign: "center", marginTop: "30px" }}
        data-aos="zoom-in-up"
      >
        Our Brand Partners
      </h1>
      <Wrapper className={isVertical ? "wrapper--vertical" : ""}>
        {/* <ToggleButton
        className={isVertical ? "toggle--vertical" : ""}
        onClick={toggleDirection}
      >
        <span>Toggle scroll axis</span>
        <FaArrowsAltH aria-hidden="true" />
      </ToggleButton> */}

        <StyledMarquee
          className={`marquee ${isVertical ? "marquee--vertical" : ""}`}
        >
          <MarqueeGroup className="marquee__group">
            <StyledImage src={paloato} alt="Palo Alto" />
            <StyledImage src={mca} alt="mca" />
            <StyledImage src={kaspersky} alt="kaspersky" />
            <StyledImage src={Trendmicro} alt="trendmicro" />
            <StyledImage src={Check_Point} alt="check" />
            <StyledImage src={fortinet} alt="fortinet" />
            <StyledImage src={Cisco} alt="cisco" />
            <StyledImage src={cyberoam} alt="cyberboam" />
            <StyledImage src={sophos} alt="sophos" />
            <StyledImage src={SonicWall} alt="sonicwall" />
          </MarqueeGroup>

          <MarqueeGroup className="marquee__group" aria-hidden="true">
            <StyledImage src={paloato} alt="palota" />
            <StyledImage src={kaspersky} alt="kaspersky" />
            <StyledImage src={mca} alt="mca" />
            <StyledImage src={Trendmicro} alt="trendmicro" />
            <StyledImage src={Check_Point} alt="check" />
            <StyledImage src={fortinet} alt="fortinet" />
            <StyledImage src={Cisco} alt="cisco" />
            <StyledImage src={cyberoam} alt="cyberboam" />
            <StyledImage src={sophos} alt="sophos" />
            <StyledImage src={SonicWall} alt="sonicwall" />
          </MarqueeGroup>
        </StyledMarquee>
      </Wrapper>
    </>
  );
};

export default LogoSlider;
