import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import videoPlay from "../assets/cyboid.mp4";
import image from "../assets/images/banner/about.png";

const scrolling = keyframes`
   0% {
     opacity: 0;
    transform: translateY(-20px);
   }
   50% {
     opacity: 1;
  }
 100% {
     opacity: 0;
     transform: translateY(20px);
   }
`;

const Hero = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2rem;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  @media (max-width: 1024px) {
    height: 100vh;
  }

  @media (max-width: 768px) {
    height: 90vh;
    // padding: 1rem;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  z-index: -1;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  @media (max-width: 480px) {
    width: 100vw;
    height: 100vh;
    object-fit: fill;
  }
`;

const fadeInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const GetStartedButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 8px 24px;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.5s;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  min-width: 150px;

  &:hover {
    color: white;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: #57eef1;
    transition: all 0.5s ease;
  }

  &:hover::before {
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-image: linear-gradient(120deg, #4b2999 0%, #57eef1 100%);
    transition: all 0.5s ease;
    z-index: -1;
  }

  &:hover::after {
    height: 100%;
    transition-delay: 0.4s;
  }
`;

const ScrollDownContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 20px;
  text-align: center;
  color: rgba(122, 122, 124, 0.918);
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  transition: opacity 0.5s ease;

  @media (max-width: 768px) {
    bottom: 10px;
    right: 20px;
  }
`;

const MouseButton = styled.span`
  margin: 10px auto;
  width: 30px;
  height: 50px;
  border: 4px solid #fff;
  border-radius: 30px;
  display: flex;
  position: relative;
`;

const MouseScroll = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  background: linear-gradient(
    170deg,
    rgba(122, 122, 124, 0.918),
    rgb(123, 124, 124)
  );
  border-radius: 50%;
  margin: auto;
  animation: ${scrolling} 1.5s linear infinite;
`;

const GetStartedButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;

  @media (max-width: 768px) {
    bottom: 10px;
    left: 10px;
  }
`;

const HeroSection = () => {
  const [showText, setShowText] = useState(false);
  const [showScroll, setShowScroll] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowScroll(false);
      } else {
        setShowScroll(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const playVideo = () => {
    if (videoRef.current) {
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch(() => {
          console.log("Autoplay was prevented on iOS");
        });
      }
    }
  };

  playVideo();

  const handleVideoEnd = () => {
    // setShowText(true);
    setTimeout(() => {
      // setShowText(false);
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      videoRef.current.muted = true;
    }, 1);
  };

  return (
    <Hero>
      <VideoBackground
        ref={videoRef}
        onEnded={handleVideoEnd}
        muted
        loop={false}
        autoPlay
        preload="auto"
        playsInline
        // poster={image}
      >
        <source
          // src="https://playful-centaur-3d2cfd.netlify.app/assets/cyboid.mp4"
          src={videoPlay}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </VideoBackground>
      {/* <TextContent hidden={!showText}>
        <h1>
          Revolutionize Your Business with Cutting-Edge Software Solutions
        </h1>
      </TextContent> */}
      {/* Get Started Button */}
      <GetStartedButtonContainer>
        <GetStartedButton as={Link} to="/products">
          Get Started
          <svg
            class="w-4 h-4 ml-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </GetStartedButton>
      </GetStartedButtonContainer>
      {/* Scroll Down Button */}
      <ScrollDownContainer hidden={!showScroll}>
        <MouseButton>
          <MouseScroll />
        </MouseButton>
        {/* <span>Scroll Down</span> */}
      </ScrollDownContainer>
    </Hero>
  );
};

export default HeroSection;
