import React from "react";
import firewall from "../assets/images/solution-img.png";
import logo1 from "../assets/brand/Cisco.png";
import logo2 from "../assets/brand/Aruba.png";
import logo3 from "../assets/brand/fortinet.png";
import logo4 from "../assets/brand/Cambium.png";
import logo5 from "../assets/brand/Netgear.png";
import logo6 from "../assets/brand/D-Link.png";
import PageTitleArea from "../components/PageTitleArea";
import ProductSection from "./ProductSection";

const WirelessProduct = () => {
  const images = [
    { src: logo1, alt: "TechCrunch Logo 1" },
    { src: logo2, alt: "TechCrunch Logo 2" },
    { src: logo3, alt: "TechCrunch Logo 3" },
    { src: logo4, alt: "TechCrunch Logo 4" },
    { src: logo5, alt: "TechCrunch Logo 4" },
    { src: logo6, alt: "TechCrunch Logo 4" },
  ];

  return (
    <>
      <PageTitleArea
        title="Wireless"
        breadcrumb="Wireless"
        products="Our Products"
        condition={false}
      />
      <ProductSection
        subtitle="Wireless"
        title="Wireless technology enables the transmission of data and communication signals without the need for physical connections, offering flexibility, mobility, and convenience in accessing networks and internet services across various devices and locations."
        images={images}
        backgroundImage={firewall}
      />
    </>
  );
};

export default WirelessProduct;
