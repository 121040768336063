import Airtable from "airtable";

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

export const createRecord = async (data) => {
  // console.log("API Key:", process.env.REACT_APP_AIRTABLE_API_KEY);
  // console.log("Base ID:", process.env.REACT_APP_AIRTABLE_BASE_ID);

  console.log(data, "in create");
  try {
    const records = await base("Contact_Form").create([
      {
        fields: {
          Name: data.name,
          Email: data.email,
          Contact: data.phone,
          Subject: data.subject,
          Message: data.message,
        },
      },
    ]);

    return records;
  } catch (error) {
    console.error("Error creating record:", error);
    throw error;
  }
};
