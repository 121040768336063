import React from "react";
import styled from "styled-components";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  img {
    width: 100%;
    height: auto;
    width: 80px;
    object-fit: cover;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* filter: brightness(1.5); */
    /* opacity: 50%; */
  }
`;

const ImageGrid = ({ images }) => {
  return (
    <GridWrapper>
      {images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} />
      ))}
    </GridWrapper>
  );
};

export default ImageGrid;
