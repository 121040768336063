import React from "react";
import ProductPage from "./ProductSection";
import storage from "../assets/images/storage.jpg";
import logo1 from "../assets/brand/AmericanMegatrands.png";
import logo2 from "../assets/brand/ibm.png";
import logo3 from "../assets/brand/emc.png";
import logo4 from "../assets/brand/netapp.png";
import logo5 from "../assets/brand/Qnap.png";
// import logo3 from "../assets/images/techcrunch.png";
// import logo4 from "../assets/images/techcrunch.png";
import PageTitleArea from "../components/PageTitleArea";

const StorageProduct = () => {
  const storageImages = [
    { src: logo1, alt: "American Megatrands" },
    { src: logo2, alt: "IBM" },
    { src: logo3, alt: "emc" },
    { src: logo4, alt: "netapp" },
    { src: logo5, alt: "qnap" },
  ];

  return (
    <>
      <PageTitleArea
        title="Storage"
        breadcrumb="Storage"
        products="Our Products"
        condition={false}
      />
      <ProductPage
        subtitle="Storage"
        title="Storage is the process of storing, managing, and preserving digital data, files, and information, ensuring accessibility, reliability, and scalability for efficient data management and retrieval across various devices and platforms."
        images={storageImages}
        backgroundImage={storage}
      />
    </>
  );
};

export default StorageProduct;
