import PageTitleArea from "./PageTitleArea";
import VideoPlayer from "./VideoPlayer";
import React, { useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import backgroundLogo from "../assets/images/banner/home-03-bg.png";
// import cameraImage from "../assets/images/banner/home-bg-3.jpg";
import cameraImage from "../assets/images/whychoose.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const CheckmarkIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    fill: #0e1625;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  background-color: #0e1625;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;
  padding-left: 3rem;
  padding-bottom: 2rem;
  padding-right: 3rem;
  color: white;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${backgroundLogo});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    opacity: 0.2;
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media (max-width: 1024px) {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`;

const RightSection = styled.div`
  flex: 1;
  background-color: transparent;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    /* margin-top: 1.5rem; */
    width: 100%;
    height: auto;
  }
`;

const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  z-index: 2;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Para = styled.p`
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 0.6rem;
    text-align: justify;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin-top: 1rem;
  color: #ffffff;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ServiceCard = styled.div`
  background-color: transparent;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  color: white;
  z-index: 2;

  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  p {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #d0d5de;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`;

const CameraPlaceholder = styled.img`
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const Button = styled.button`
  background-image: linear-gradient(120deg, #4b2999 0%, #57eef1 100%);
  padding: 0.8rem 1.5rem;
  width: 40%;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 2;
  margin-top: 1.5rem;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SliderWrapper = styled(Slider)`
  max-width: 600px;
  margin-top: 2rem;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

function About() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: true,
  };
  return (
    <>
      <Container>
        <LeftSection data-aos="fade-up">
          {/* <Title>Why Choose Us?</Title>
          <Subtitle>
            We pride ourselves on being the preferred choice for businesses
            seeking top-notch software solutions. Our clients consistently
            choose us because we build lasting partnerships by prioritizing your
            success and growth
          </Subtitle> */}

          {/* Slick Slider for Card section */}
          <SliderWrapper {...settings}>
            <Card>
              <ServiceCard>
                <CheckmarkIcon>
                  <svg viewBox="0 0 20 20">
                    <path d="M8.5 13.5L5.5 10.5L6.92 9.08L8.5 10.67L13.08 6.08L14.5 7.5L8.5 13.5Z" />
                  </svg>
                </CheckmarkIcon>
                <div>
                  <h2>Vision</h2>
                  <Para>
                    Our vision is to transform the software landscape with
                    innovative, reliable, and scalable solutions. We aim to be
                    the trusted tech ally for businesses worldwide, driving
                    value through our strategic technological solutions.
                  </Para>
                </div>
              </ServiceCard>
            </Card>

            <Card>
              <ServiceCard>
                <CheckmarkIcon>
                  <svg viewBox="0 0 20 20">
                    <path d="M8.5 13.5L5.5 10.5L6.92 9.08L8.5 10.67L13.08 6.08L14.5 7.5L8.5 13.5Z" />
                  </svg>
                </CheckmarkIcon>
                <div>
                  <h2>Mission</h2>
                  <Para>
                    Our mission is to deliver exceptional and cost-effective
                    software products and services that surpass client
                    expectations. We commit to excellence, integrity, and
                    continuous improvement, leveraging our expertise and OEM
                    collaborations to enhance productivity, drive growth, and
                    provide a competitive edge.
                  </Para>
                </div>
              </ServiceCard>
            </Card>
          </SliderWrapper>
          {/* <Button as={Link} to="/about-us">
            Learn More
          </Button> */}
        </LeftSection>
        <RightSection data-aos="fade-up">
          <CameraPlaceholder src={cameraImage} alt="Camera Placeholder" />
        </RightSection>
      </Container>
    </>
  );
}

export default About;
