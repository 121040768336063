import React, { createContext, useState } from "react";

export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [hasVideoPlayed, setHasVideoPlayed] = useState(false);

  return (
    <VideoContext.Provider value={{ hasVideoPlayed, setHasVideoPlayed }}>
      {children}
    </VideoContext.Provider>
  );
};
