import React from "react";
import firewall from "../assets/images/vmware.png";
import logo1 from "../assets/brand/PatchManagement.png";
import logo2 from "../assets/brand/ISS.png";
import logo3 from "../assets/brand/Check_Point.png";
// import logo4 from "../assets/images/techcrunch.png";
import PageTitleArea from "../components/PageTitleArea";
import ProductSection from "./ProductSection";

const IbmAirwatchProduct = () => {
  const images = [
    { src: logo1, alt: "TechCrunch Logo 1" },
    { src: logo2, alt: "TechCrunch Logo 2" },
    { src: logo3, alt: "TechCrunch Logo 3" },
    // { src: logo4, alt: "TechCrunch Logo 4" },
  ];

  return (
    <>
      <PageTitleArea
        title="IBM Airwatch"
        breadcrumb="IBM Airwatch"
        products="Our Products"
        condition={false}
      />
      <ProductSection
        subtitle="IBM Airwatch"
        title="This is now known as VMware Workspace ONE, is an enterprise mobility management (EMM) platform designed to manage mobile devices, applications, and content within organizations."
        images={images}
        backgroundImage={firewall}
      />
    </>
  );
};

export default IbmAirwatchProduct;
