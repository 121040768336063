import React, { useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import backgroundLogo from "../assets/images/banner/home-03-bg.png";
import cameraImage from "../assets/images/banner/whyus.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  height: 404px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    margin-bottom: 20px;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  background-color: #0e1625;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 3rem;
  color: white;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${backgroundLogo});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    opacity: 0.2;
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    text-align: center;
  }
  @media (max-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const Div = styled.div`
  align-content: center;
`;
const RightSection = styled.div`
  flex: 1;
  background-color: transparent;
  /* border-radius: 16px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    /* margin-top: 1rem; */
    width: 100%;
    border-radius: 0px !important;
    /* padding: 0 1rem; */
  }
`;

const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  z-index: 2;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin-top: 1rem;
  color: #ffffff;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: justify;
  }
`;

const ServiceCard = styled.div`
  background-color: transparent;
  border-bottom: 1px solid #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  color: white;
  z-index: 2;

  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  p {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #d0d5de;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`;

const CheckmarkIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    fill: #0e1625;
  }

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;

const CameraPlaceholder = styled.img`
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    /* border-radius: 8px; */
  }
`;

const SliderWrapper = styled(Slider)`
  max-width: 600px;
  margin-top: 2rem;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const WhatWeDoSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Container>
      <LeftSection data-aos="fade-up">
        <Title>Why Choose Us?</Title>
        <Subtitle>
          We pride ourselves on being the preferred choice for businesses
          seeking top-notch software solutions. Our clients consistently choose
          us because we build lasting partnerships by prioritizing your success
          and growth.
        </Subtitle>

        <SliderWrapper {...settings}>
          <Div>
            <ServiceCard>
              <CheckmarkIcon>
                <svg viewBox="0 0 20 20">
                  <path d="M8.5 13.5L5.5 10.5L6.92 9.08L8.5 10.67L13.08 6.08L14.5 7.5L8.5 13.5Z" />
                </svg>
              </CheckmarkIcon>
              <div>
                <h2>Elite Partnerships</h2>
                <p>
                  We work with leading OEMs to provide the highest quality
                  software solutions.
                </p>
              </div>
            </ServiceCard>
          </Div>
          <Div>
            <ServiceCard>
              <CheckmarkIcon>
                <svg viewBox="0 0 20 20">
                  <path d="M8.5 13.5L5.5 10.5L6.92 9.08L8.5 10.67L13.08 6.08L14.5 7.5L8.5 13.5Z" />
                </svg>
              </CheckmarkIcon>
              <div>
                <h2>Proven Track Record</h2>
                <p>
                  Trusted by clients across industries for our commitment to
                  excellence and reliability.
                </p>
              </div>
            </ServiceCard>
          </Div>
          <Div>
            <ServiceCard>
              <CheckmarkIcon>
                <svg viewBox="0 0 20 20">
                  <path d="M8.5 13.5L5.5 10.5L6.92 9.08L8.5 10.67L13.08 6.08L14.5 7.5L8.5 13.5Z" />
                </svg>
              </CheckmarkIcon>
              <div>
                <h2>Reliable Support</h2>
                <p>
                  Our support team is available around the clock to assist you.
                </p>
              </div>
            </ServiceCard>
          </Div>
          <Div>
            <ServiceCard>
              <CheckmarkIcon>
                <svg viewBox="0 0 20 20">
                  <path d="M8.5 13.5L5.5 10.5L6.92 9.08L8.5 10.67L13.08 6.08L14.5 7.5L8.5 13.5Z" />
                </svg>
              </CheckmarkIcon>
              <div>
                <h2>Innovative Solutions</h2>
                <p>
                  Trusted by clients across industries for our commitment to
                  innovation.
                </p>
              </div>
            </ServiceCard>
          </Div>
        </SliderWrapper>
      </LeftSection>

      <RightSection data-aos="fade-up">
        <CameraPlaceholder src={cameraImage} alt="Camera Placeholder" />
      </RightSection>
    </Container>
  );
};

export default WhatWeDoSection;
