import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Section = styled.section`
  padding: 50px 0;
  text-align: center;
  background-color: #f9f9f9;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #222;
  font-weight: bold;
  margin-bottom: 40px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #ff6f61;
  margin-bottom: 40px;
  display: block;
  margin-bottom: 12px;
  color: #e21f36;
  font-weight: 600;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
`;

const Card = styled.div`
  background-color: white;
  padding: 40px 20px 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 22%;
  min-width: 250px;
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    top 0.3s ease-in-out;

  /* On hover, the card moves up and shadow deepens */
  &:hover {
    transform: translateY(-15px); /* Move the entire card upwards */
    top: -5px;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  font-size: 40px;
  color: #fff;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  background-color: #e21f36;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #e21f36;

  /* Outer Ring */
  &:before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    width: 82px;
    height: 82px;
    border: 1px solid #e21f36;
    border-radius: 50%;
    box-sizing: border-box;
  }
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #222;
  margin-top: 40px;
`;

const CardDescription = styled.p`
  font-size: 14px;
  color: #555;
`;

const ProtectSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <Section>
      <Subtitle>Our Approach</Subtitle>
      <Title>How Can I Protect My Website From Cyber Attacks</Title>

      <CardsContainer>
        <Card data-aos="fade-up">
          <IconWrapper>
            <i className="fas fa-key"></i>
          </IconWrapper>
          <CardTitle>Identifying Threats</CardTitle>
          <CardDescription>
            Lorem ipsum dolor amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt.
          </CardDescription>
        </Card>

        <Card data-aos="fade-up">
          <IconWrapper>
            <i className="fas fa-lock"></i>
          </IconWrapper>
          <CardTitle>Keep Software Updated</CardTitle>
          <CardDescription>
            Lorem ipsum dolor amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt.
          </CardDescription>
        </Card>

        <Card data-aos="fade-up">
          <IconWrapper>
            <i className="fas fa-link"></i>
          </IconWrapper>
          <CardTitle>Check Website URL</CardTitle>
          <CardDescription>
            Lorem ipsum dolor amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt.
          </CardDescription>
        </Card>

        <Card data-aos="fade-up">
          <IconWrapper>
            <i className="fas fa-shield-alt"></i>
          </IconWrapper>
          <CardTitle>Use Antivirus Software</CardTitle>
          <CardDescription>
            Lorem ipsum dolor amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt.
          </CardDescription>
        </Card>
      </CardsContainer>
    </Section>
  );
};

export default ProtectSection;
