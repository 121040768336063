import React from "react";
import firewall from "../assets/images/firewall.jpg";
import logo1 from "../assets/brand/sophos.png";
import logo2 from "../assets/brand/Check_Point.png";
// import logo3 from "../assets/brand/Check_Point.png";
import logo4 from "../assets/brand/fortinet.png";
import logo5 from "../assets/brand/cyberoam.png";
import logo6 from "../assets/brand/watchguard.png";
import logo7 from "../assets/brand/Paloalto.png";
import logo8 from "../assets/brand/SonicWall.png";
import logo9 from "../assets/brand/sec_dark_one.png";
// import logo2 from "../assets/images/techcrunch.png";
// import logo3 from "../assets/images/techcrunch.png";
// import logo4 from "../assets/images/techcrunch.png";
import PageTitleArea from "../components/PageTitleArea";
import ProductSection from "./ProductSection";

const FirewallProduct = () => {
  const images = [
    { src: logo9, alt: "TechCrunch Logo 2" },
    { src: logo1, alt: "TechCrunch Logo 1" },
    { src: logo2, alt: "TechCrunch Logo 2" },

    { src: logo4, alt: "TechCrunch Logo 4" },
    { src: logo5, alt: "TechCrunch Logo 4" },
    { src: logo6, alt: "TechCrunch Logo 4" },
    { src: logo7, alt: "TechCrunch Logo 4" },
    { src: logo8, alt: "TechCrunch Logo 4" },
  ];

  return (
    <>
      <PageTitleArea
        title="Firewall"
        breadcrumb="Firewall"
        products="Our Products"
        condition={false}
      />
      <ProductSection
        subtitle="Firewall"
        title="Firewalls are digital barriers that monitor and control incoming and outgoing network traffic based on predetermined security rules, safeguarding networks from unauthorized access and cyber threats while preserving data integrity and confidentiality."
        images={images}
        backgroundImage={firewall}
      />
    </>
  );
};

export default FirewallProduct;
